import { render, staticRenderFns } from "./ViewSales.vue?vue&type=template&id=04a46152"
import script from "./ViewSales.vue?vue&type=script&setup=true&lang=ts"
export * from "./ViewSales.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ViewSales.vue?vue&type=style&index=0&id=04a46152&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChromeNotification: require('/app/components/Chrome/ChromeNotification.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,PushButton: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/PushButton/PushButton.vue').default,WidgetLayoutRowCellDouble: require('/app/components/Widget/WidgetLayoutRowCellDouble.vue').default,SimpleTableCell: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableCell.vue').default,SimpleTableRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableRow.vue').default,TickBox: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/TickBox/TickBox.vue').default,SimpleTable: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTable.vue').default,WidgetLayoutRowCellSingle: require('/app/components/Widget/WidgetLayoutRowCellSingle.vue').default,WidgetLayoutRowSpacer: require('/app/components/Widget/WidgetLayoutRowSpacer.vue').default,WidgetLayoutRowCellFourths: require('/app/components/Widget/WidgetLayoutRowCellFourths.vue').default,EmptyState: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/EmptyState/EmptyState.vue').default})
